* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  text-transform: capitalize;
}

.recharts-legend-item-text {
  text-transform: uppercase;
}

.ui.fluid.input > input {
  width: 0 !important;
  text-transform: none;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background: #1b1c1d;
  text-transform: none;
}

.item {
  text-transform: uppercase;
}

.userEmail {
  text-transform: lowercase;
}
.bankName {
  text-transform: uppercase;
}

.sentimentDropdown {
  position: relative;
  display: inline-block;
}

.sentimentDropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.sentimentDropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.sentimentDropdown-content a:hover {
  background-color: #f1f1f1;
}
.sentimentDropdown:hover .sentimentDropdown-content {
  display: block;
}
