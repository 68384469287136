#root > .ui.fluid.container {
	height: 100vh;
	margin: 0!important;
}
.ui.container > .ui.menu.page-header {
	border-radius: 0;
	margin: 0;
	height: 4.5em;
}
.ui.container > .ui.menu.page-header .item {
	padding: 1em;
}
.ui.container > .ui.menu.page-header .item.brand {
	font-size: 1.2em;
}
.ui.container > .ui.menu.page-header .item.control {
	padding: 0;
}
.ui.container > .ui.menu.page-header .item.control > .dropdown {
	padding: 1em;
}
.ui.container > .page-header > .ui.dropdown.control .menu {
	margin: 0.5em;
}

.ui.container > .page-content {
	height: calc(100% - 4.5em);
	overflow-y: scroll;
}
.ui.container > .page-content > .ui.segment {
	max-width: 1200px;
	margin: 1.5em auto;
}

.page-content {
	margin-top: 80px; /* adapt to the current page spacing */
}
.operation.page-content *,
.ui.popup * {
	text-transform: initial;
}



.ui.container.page-content > .ui.segment {
	margin: 1em auto;
}

.ui.segment.operation {
	padding: 0;
}

.ui.segment.operation .segment-tab {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	margin-bottom: 0;
}

.ui.segment.operation .ui.container.active.tab > .ui.segment {
	margin: 1em 0;
}
.ui.basic.ui.segment.operation-role-select,
.ui.segment.operation-data-table,
.ui.segment.operation-post-info {
	padding: 0;
}

.ui.basic.ui.segment.operation-role-select > .ui.dropdown > * {
	margin-right: 0.5em;
}
.ui.basic.ui.segment.operation-role-select > .ui.dropdown > .icon {
	float: left;
}

.ui.segment.operation-post-info {
	margin: 0;
	text-align: right;
}

.ui.form.operation-post-search-form .field:first-child {
	flex: 1;
}
.ui.form.operation-post-search-form .field > .ui.button {
	height: 100%;
	margin: 0;
}

.ui.dropdown.operation-data-dropdown .menu > .item {
	padding-left: 2.4em!important;
}

body > .ui.modals.dimmer {
	background-color: #000C;
	z-index: 1040;
}
.ui.modal {
	top: revert;
    left: revert;
	width: 75%;
	height: auto;
	overflow: revert;
}
.ui.modal > .header {
	height: 3.2em;
}
.ui.modal > .content {
	max-height: calc(100vh - 3.5rem - 7.7em);
	overflow-y: scroll;
}
.ui.modal > .actions {
	height: 4.5em;
}
.ui.modal > .actions > .ui.button.padding-placeholder {
	width: 0;
	visibility: hidden;
}
.ui.selectable.table tr {
	cursor: pointer;
}
.ui.segment.operation .ui.container.active.tab > .ui.segment.operation-data-table {
	margin-top: 0;
}
.ui.segment.operation-data-table td {
	vertical-align: middle;
}
.ui.segment.operation-data-table td > .ui.label:first-child {
	margin-right: 0.75em;
}
.ui.segment.operation-data-table td > .one-liner {
	white-space: nowrap;
}
.ui.segment.operation-data-table td > .long-text {
	overflow: hidden;
	max-width: 12em;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ui.list.tree-view .ui.button.tree-view-fold-btn {
	padding: 0;
    background: #0000;
}
.ui.list.tree-view .tree-view.item {
	border-left: 0.15em solid;
	padding: 0;
    padding-left: 0.5em;
    margin-left: 0.525em;
}
.ui.list .tree-view.item.hidden {
	display: none;
}

.ui.button.date-picker-button {
	padding: 0 1.5em;
}
.ui.modal.date-picker-modal {
	width: unset;
}

.ui.buttons.operation-platform-buttons .ui.button.active.play_store {
	background-color: #689f38;
}
.ui.buttons.operation-platform-buttons .ui.button.active.app_store {
	background-color: #0071e3;
}

.ui.popup > ul {
	margin-bottom: 0;
}
.ui.popup ul > li {
	margin-top: 0.4em;
}

.ui.form .field.operation-posts-date-label {
	padding-right: 0;
}
.ui.form .field.operation-posts-date-label .label {
	height: 100%;
	background-color: #d1e2f0;
	padding-top: 0;
	padding-bottom: 0;
	text-align: right;
	line-height: 1.6;
}

.ui.segment.operation-results-info {
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
}
.ui.segment.operation-results-info > button {
	transform: translateY(-0.35em);
}

div.sample {
	width: 1em;
	height: 1em;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.2em;
}
table.ui.table tr.sla-green,
div.sample.sla-green {
	background-color: #0f01;
}
table.ui.table tr.sla-lime,
div.sample.sla-lime {
	background-color: #bf02;
}
table.ui.table tr.sla-yellow,
div.sample.sla-yellow {
	background-color: #fd03;
}
table.ui.table tr.sla-orange,
div.sample.sla-orange {
	background-color: #f804;
}
table.ui.table tr.sla-red,
div.sample.sla-red {
	background-color: #f004;
}
table.ui.table tr.sla-grey,
div.sample.sla-grey {
	background-color: #0002;
}

/* Alt SLA Indicators (Less Obtrusive) */
/* table.ui.table tr.sla-green,
table.ui.table tr.sla-lime,
table.ui.table tr.sla-yellow,
table.ui.table tr.sla-orange,
table.ui.table tr.sla-red,
table.ui.table tr.sla-grey {
	position: relative;
}
table.ui.table tr.sla-green::after,
table.ui.table tr.sla-lime::after,
table.ui.table tr.sla-yellow::after,
table.ui.table tr.sla-orange::after,
table.ui.table tr.sla-red::after,
table.ui.table tr.sla-grey::after {
	content: '';
	width: 0.6em;
	height: 100%;
	position: absolute;
	right: 0;
	display: inline-block;
}
table.ui.table tr.sla-green::after {
	background-color: #0f01;
}
table.ui.table tr.sla-lime::after {
	background-color: #bf06;
}
table.ui.table tr.sla-yellow::after {
	background-color: #fd08;
}
table.ui.table tr.sla-orange::after {
	background-color: #f80a;
}
table.ui.table tr.sla-red::after {
	background-color: #f00;
}
table.ui.table tr.sla-grey::after {
	background-color: #0002;
} */